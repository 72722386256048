<mat-toolbar color="primary">
  <span>{{title}}</span>
</mat-toolbar>

<div class="content">
  <mat-card *ngIf="scannedProduct">
    <mat-card-title>{{ scannedProduct.Name }}</mat-card-title>
    <mat-card-subtitle>{{ scannedProduct.Category }}</mat-card-subtitle>
    <mat-card-content>
      <p [class.important]="scannedProduct.DescriptionImportant">
        {{ scannedProduct.Description}}
      </p>
      <a [href]="scannedProduct.ExternalLink">
        {{ scannedProduct.ExternalLink }}
      </a>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button
              *ngIf="selectedDevice"
              (click)="scan_click()">Scan</button>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="!scannedProduct">
    <mat-card-header>
      <mat-icon mat-card-avatar>crop_free</mat-icon>
      <mat-card-title>Scan demo</mat-card-title>
      <mat-card-subtitle>{{ selectedDevice?.label }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>

      <div class="viewport">

        <barcode-scanner-livestream type="code_39"
                                    [deviceId]="selectedDevice?.deviceId"
                                    (valueChanges)="onValueChanges($event)"
                                    (started)="onStarted($event)"></barcode-scanner-livestream>
      </div>

    </mat-card-content>

    <mat-card-actions>

      <button mat-button
              (click)="testScan()">
        Test
      </button>

      <mat-form-field appearance="fill">
        <mat-label>Select a device</mat-label>
        <mat-select [(value)]="selectedDevice"
                    (valueChange)="deviceValueChange($event)">
          <mat-option>None</mat-option>
          <mat-option *ngFor="let device of devices"
                      [value]="device">{{ device.label }}</mat-option>
        </mat-select>
      </mat-form-field>

    </mat-card-actions>
  </mat-card>
</div>

<p>{{ error }}</p>
