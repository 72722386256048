import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { Product } from '../model/product.model';
import { BarcodeService } from '../services/barcode.service';

@Component({
  selector: 'rd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild(BarcodeScannerLivestreamComponent) barcodeScanner: BarcodeScannerLivestreamComponent;

  title = 'RDSuite - Barcodescanner';

  devices: Array<InputDeviceInfo> = [];
  selectedDevice: InputDeviceInfo;
  scanStarted = false;
  scannedProduct: Product;
  error: string;

  constructor(
    private element: ElementRef<HTMLElement>,
    private barcodeService: BarcodeService
  ) { }

  ngOnInit(): void {

    if (!navigator.mediaDevices || !(typeof navigator.mediaDevices.getUserMedia === 'function')) {
      this.error = 'No media found';
      return;
    }

    this.getMediaDevices();
    this.barcodeService.loadProducts();
  }

  scan_click(): void {
    try {
      this.scannedProduct = undefined;

      setTimeout(() => {
        this.initDevice(this.selectedDevice);
      }, 200);

    } catch (e) {
      console.error(e);
      this.error = e;
    }
  }

  private getMediaDevices(): void {

    if (!navigator.mediaDevices) {
      this.error = 'No MediaDevices';
      return;
    }

    navigator.mediaDevices
      .enumerateDevices()
      .then((devices: InputDeviceInfo[]) => {
        console.log('devices: ', devices);
        this.devices = devices.filter(device => device.kind === 'videoinput');

        if (this.devices.length === 1) {
          this.initDevice(this.devices[0]);
        }
      })
      .catch(err => {
        this.error = err;
      });

  }

  private initDevice(device: InputDeviceInfo): void {
    this.selectedDevice = device;

    if (this.scanStarted) {
      this.scanStarted = false;
      this.barcodeScanner?.stop();
    }

    this.barcodeScanner.start();
  }

  testScan(): void {
    this.onValueChanges({
      codeResult: {
        code: 625923
      }
    });
  }

  deviceValueChange(e: Event): void {
    this.initDevice(this.selectedDevice);
  }

  onValueChanges(result): void {
    this.scannedProduct = this.barcodeService.findProduct(result.codeResult.code);
    this.barcodeScanner.stop();
    this.scanStarted = false;
  }

  onStarted(started: any): void {
    this.scanStarted = true;
  }

}
