
export class Product {

  constructor(
    public Id: number,
    public Number: number,
    public Name: string,
    public Category: string,
    public Description: string,
    public DescriptionImportant: boolean,
    public ExternalLink: string,
    public Status: boolean,
    public AliasName: string[],
    public DueDate: string
  ) { }

  static fromHttpResponse(item: any): Product {
    return new Product(
      item.Id,
      item.Number,
      item.Name,
      item.Category,
      item.Description,
      item.DescriptionImportant,
      item.ExternalLink,
      item.Status,
      item.AliasName,
      item.DueDate
    );
  }
}
