import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../model/product.model';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BarcodeService {

  products: Product[] = [];

  constructor(
    private http: HttpClient
  ) { }

  loadProducts(): void {

    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJtYW5kYW50X2lkIjoiMSIsInVzZXJfaWQiOiIxNjMiLCJsYWdlcmxpc3RlX2lkIjoiNSIsImxhZ2VyX2JlcmVjaHRpZ3VuZyI6IlswXSJ9.gd_eY8WXDShJTNM8If7El3lCm3LUroGCjt6bR-2q8HY';

    const httpOptions = {
      headers: new HttpHeaders({
        'X-Token': token
      })
    };


    this.http
      .get('https://nidabeta.rdsuite.de/api/v1/store/products', httpOptions)
      .pipe(
        tap((lst: Array<any>) => this.products = lst.map(x => Product.fromHttpResponse(x)))
      )
      .subscribe();

  }

  findProduct(code: string): Product {

    const found = this.products.find(p => {
      if (p.Number === Number.parseInt(code, 10)) {
        return true;
      }
      else { return false; }
    });

    return found;

  }
}
